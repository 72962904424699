import { FC } from 'react';
import IconProps from './IconProps';

const SecurityIcon: FC<IconProps> = (props) => {
  const { onClick, className, accentColor = '#C5A76B', gradient } = props;
  const cursor = onClick ? 'cursor-pointer' : '';
  const attr = { onClick: onClick, className: `inline ${cursor} ${className}` };

  if (gradient) {
    return (
      <svg {...attr} viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.5058 41.6956C12.624 41.875 13.5887 40.9729 13.5887 39.8404C13.5887 38.8301 12.8127 37.9983 11.8177 37.8231C6.99118 36.9733 3.88248 34.5569 3.88248 32.3219V28.4265C6.70374 30.413 10.8451 31.6619 15.5299 31.6619C16.7933 31.6677 18.0533 31.5724 19.2979 31.3776C19.7139 31.3126 20.1281 31.2364 20.5402 31.1491C23.3531 30.5538 23.8779 26.2983 20.5402 27.1671C20.1292 27.2741 19.7148 27.3673 19.2979 27.4465C18.0589 27.6818 16.7967 27.7939 15.5299 27.7794C8.66439 27.7794 3.88248 24.7123 3.88248 21.9557V18.0732C6.70374 20.0598 10.8451 21.3086 15.5299 21.3086C20.2148 21.3086 24.3561 20.0727 27.1773 18.0732V18.9402C27.1773 20.0123 28.0465 20.8814 29.1186 20.8814C30.1907 20.8814 31.0598 20.0123 31.0598 18.9402V11.6024C31.0598 6.16049 24.2396 1.89624 15.5299 1.89624C6.82022 1.89624 0 6.16049 0 11.6024V32.309C0 36.876 4.80524 40.6208 11.5058 41.6956ZM15.5299 5.77872C22.3954 5.77872 27.1773 8.84587 27.1773 11.6024C27.1773 14.359 22.3954 17.4261 15.5299 17.4261C8.66439 17.4261 3.88248 14.359 3.88248 11.6024C3.88248 8.84587 8.66439 5.77872 15.5299 5.77872Z"
          fill="url(#paint0_linear_11030_107487)"
        />
        <path
          d="M39.1908 30.4979V28.1736C39.1908 26.7367 38.658 25.3586 37.7097 24.3425C36.7614 23.3264 35.4752 22.7556 34.134 22.7556C32.7929 22.7556 31.5067 23.3264 30.5583 24.3425C29.61 25.3586 29.0772 26.7367 29.0772 28.1736V30.4979C28.3466 30.7313 27.7058 31.2113 27.2502 31.8663C26.7946 32.5213 26.5486 33.3165 26.5488 34.1334V37.926C26.5488 38.9318 26.9218 39.8965 27.5856 40.6078C28.2494 41.319 29.1498 41.7186 30.0886 41.7186H38.1794C39.1182 41.7186 40.0186 41.319 40.6824 40.6078C41.3463 39.8965 41.7192 38.9318 41.7192 37.926V34.1334C41.7194 33.3165 41.4734 32.5213 41.0178 31.8663C40.5623 31.2113 39.9215 30.7313 39.1908 30.4979ZM34.134 26.0064C34.6705 26.0064 35.185 26.2347 35.5643 26.6412C35.9436 27.0476 36.1567 27.5988 36.1567 28.1736V30.3408H32.1113V28.1736C32.1113 27.5988 32.3244 27.0476 32.7037 26.6412C33.0831 26.2347 33.5976 26.0064 34.134 26.0064ZM38.6851 37.926C38.6851 38.0697 38.6318 38.2075 38.537 38.3091C38.4422 38.4107 38.3136 38.4678 38.1794 38.4678H30.0886C29.9545 38.4678 29.8258 38.4107 29.731 38.3091C29.6362 38.2075 29.5829 38.0697 29.5829 37.926V34.1334C29.5829 33.9897 29.6362 33.8519 29.731 33.7503C29.8258 33.6487 29.9545 33.5916 30.0886 33.5916H38.1794C38.3136 33.5916 38.4422 33.6487 38.537 33.7503C38.6318 33.8519 38.6851 33.9897 38.6851 34.1334V37.926Z"
          fill="#5A5766"
        />
        <path
          d="M15.4384 41.9432C10.7536 41.9432 6.61228 40.6944 3.79102 38.7079V32.2371C3.79102 34.9936 8.57293 38.0608 15.4384 38.0608C17.1316 38.0802 18.8164 37.8734 20.4488 37.4485C23.7865 36.5797 23.2616 40.8352 20.4488 41.4305C18.8037 41.7787 17.1241 41.951 15.4384 41.9432Z"
          fill="url(#paint1_linear_11030_107487)"
        />
        <defs>
          <linearGradient id="paint0_linear_11030_107487" x1="-5.93738e-08" y1="14.0557" x2="33.0604" y2="25.0787" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
          <linearGradient id="paint1_linear_11030_107487" x1="0.5" y1="10.0002" x2="43" y2="32.0002" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0A2E5A" />
            <stop offset="0.156625" stopColor="#3785A1" />
            <stop offset="0.527426" stopColor="#C6A97D" />
            <stop offset="1" stopColor="#D67252" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg {...attr} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.47201 23.4538C7.10102 23.5547 7.64362 23.0473 7.64362 22.4103C7.64362 21.8419 7.20717 21.3741 6.64747 21.2755C3.93254 20.7975 2.18389 19.4383 2.18389 18.1811V15.9899C3.77085 17.1074 6.10034 17.8098 8.73557 17.8098C9.44623 17.8131 10.155 17.7595 10.8551 17.65C11.089 17.6133 11.3221 17.5705 11.5539 17.5214C13.1361 17.1865 13.4313 14.7928 11.5539 15.2815C11.3227 15.3417 11.0896 15.3941 10.8551 15.4387C10.1581 15.5711 9.44816 15.6341 8.73557 15.626C4.87372 15.626 2.18389 13.9007 2.18389 12.3501V10.1662C3.77085 11.2836 6.10034 11.9861 8.73557 11.9861C11.3708 11.9861 13.7003 11.2909 15.2872 10.1662V10.6539C15.2872 11.2569 15.7761 11.7458 16.3792 11.7458C16.9823 11.7458 17.4711 11.2569 17.4711 10.6539V6.5264C17.4711 3.46531 13.6348 1.06667 8.73557 1.06667C3.83637 1.06667 0 3.46531 0 6.5264V18.1738C0 20.7428 2.70295 22.8493 6.47201 23.4538ZM8.73557 3.25056C12.5974 3.25056 15.2872 4.97583 15.2872 6.5264C15.2872 8.07696 12.5974 9.80224 8.73557 9.80224C4.87372 9.80224 2.18389 8.07696 2.18389 6.5264C2.18389 4.97583 4.87372 3.25056 8.73557 3.25056Z"
        fill="currentColor"
      />
      <path
        d="M22.0447 17.1551V15.8476C22.0447 15.0393 21.745 14.2642 21.2116 13.6926C20.6781 13.1211 19.9547 12.8 19.2003 12.8C18.4459 12.8 17.7224 13.1211 17.1889 13.6926C16.6555 14.2642 16.3558 15.0393 16.3558 15.8476V17.1551C15.9448 17.2863 15.5844 17.5563 15.3281 17.9248C15.0719 18.2932 14.9335 18.7405 14.9336 19.2V21.3333C14.9336 21.8991 15.1434 22.4418 15.5168 22.8418C15.8902 23.2419 16.3966 23.4667 16.9247 23.4667H21.4758C22.0039 23.4667 22.5103 23.2419 22.8837 22.8418C23.2571 22.4418 23.4669 21.8991 23.4669 21.3333V19.2C23.467 18.7405 23.3287 18.2932 23.0724 17.9248C22.8162 17.5563 22.4557 17.2863 22.0447 17.1551ZM19.2003 14.6286C19.502 14.6286 19.7914 14.757 20.0048 14.9856C20.2182 15.2142 20.338 15.5243 20.338 15.8476V17.0667H18.0625V15.8476C18.0625 15.5243 18.1824 15.2142 18.3957 14.9856C18.6091 14.757 18.8985 14.6286 19.2003 14.6286ZM21.7603 21.3333C21.7603 21.4142 21.7303 21.4917 21.6769 21.5488C21.6236 21.606 21.5513 21.6381 21.4758 21.6381H16.9247C16.8493 21.6381 16.7769 21.606 16.7236 21.5488C16.6702 21.4917 16.6403 21.4142 16.6403 21.3333V19.2C16.6403 19.1192 16.6702 19.0417 16.7236 18.9845C16.7769 18.9274 16.8493 18.8952 16.9247 18.8952H21.4758C21.5513 18.8952 21.6236 18.9274 21.6769 18.9845C21.7303 19.0417 21.7603 19.1192 21.7603 19.2V21.3333Z"
        fill={accentColor}
      />
      <path
        d="M8.68547 23.5931C6.05024 23.5931 3.72075 22.8906 2.13379 21.7732V18.1333C2.13379 19.6839 4.82362 21.4092 8.68547 21.4092C9.63788 21.4201 10.5856 21.3038 11.5038 21.0648C13.3812 20.576 13.086 22.9698 11.5038 23.3046C10.5784 23.5005 9.63365 23.5974 8.68547 23.5931Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SecurityIcon;
